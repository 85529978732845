import React, { useState } from "react"
import { Button, Form } from "react-bootstrap"
import Select from "react-select"
import { Link } from "gatsby"
import { apiClient } from "../../apiClient"
import "./getstarted.css"

const GetStarted = ({ data }) => {

  const customStyles = {
    option: provided => ({
      ...provided,
      background: "#3d41600a",
      color: "#212529",
      fontWeight: "400",
      fontSize: "16px",
      padding: "12px",
      lineHeight: "16px",
      cursor: "pointer",
      ":hover": {
        background: "#fff",
        // border: "2px solid #306CFE",
      },
    }),
    menu: provided => ({
      ...provided,
      background: "#fff",
      padding: "0px",
      border: "2px solid transparent",
    }),
    control: () => ({
      background: "#3d41600a",
      border: "2px solid transparent",
      borderRadius: "10px",
      color: "#fff",
      display: "flex",
      alignItem: "center",
      height: "54px",
      margin: "10px 0",
      ":hover": {
        background: "#fff",
        border: "2px solid rgba(61, 65, 96, 0.04)",
      },
    }),
    singleValue: provided => ({
      ...provided,
      color: "#000",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "16px",
    }),
    indicatorSeparator: provided => ({
      ...provided,
      display: "none",
    }),
    placeholder: provided => ({
      ...provided,
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "19px",
      color: "#3d416066",
    }),
  }

  const errorStyle = {
    option: provided => ({
      ...provided,
      background: "#3d41600a",
      color: "#212529",
      fontWeight: "400",
      fontSize: "16px",
      padding: "12px",
      lineHeight: "16px",
      cursor: "pointer",
      ":hover": {
        background: "#fff",
      },
    }),
    menu: provided => ({
      ...provided,
      background: "#fff",
      padding: "0px",
      border: "2px solid red",
    }),
    control: () => ({
      background: "#3d41600a",
      border: "2px solid red",
      borderRadius: "10px",
      color: "#fff",
      display: "flex",
      alignItem: "center",
      height: "54px",
      margin: "10px 0",
      ":hover": {
        background: "#fff",
        border: "2px solid red",
      },
    }),
    indicatorSeparator: provided => ({
      ...provided,
      display: "none",
    }),
  }
  const successStyle = {
    option: provided => ({
      ...provided,
      background: "#3d41600a",
      color: "#212529",
      fontWeight: "400",
      fontSize: "16px",
      padding: "12px",
      lineHeight: "16px",
      cursor: "pointer",
      ":hover": {
        background: "#fff",
      },
    }),
    menu: provided => ({
      ...provided,
      background: "#fff",
      padding: "0px",
      border: "2px solid transparent",
    }),
    control: () => ({
      background: "#3d41600a",
      border: "2px solid #36A836",
      borderRadius: "10px",
      color: "#fff",
      display: "flex",
      alignItem: "center",
      height: "54px",
      margin: "10px 0",
      ":hover": {
        background: "#fff",
        border: "2px solid #36A836",
      },
    }),
    indicatorSeparator: provided => ({
      ...provided,
      display: "none",
    }),
  }

  const [txt, setTxt] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    website: "",
    socialMedia: "",
  })

  const submitForm = () => {
    console.log(formData);
    let res = apiClient.post('/api/bookings', {
      data: { ...formData, submission_date: new Date() }
    }).then(() => {

      setFormData({})
      alert(data?.form_success_message || "You message sent successfully!.")
      window.location.reload()
    }).catch(() => {
      alert(data?.form_error_message || "Oops! Something went wrong while submitting your message.")
    })

  }

  const [formData, setFormData] = useState({})


  const renderFormGroupField = (field) => {

    if (field?.input_type === 'checkbox') {
      return (
        <Form.Group controlId="formBasicCheckbox">
          <Form.Check
            key={field?.label}
            className="getstart-form-check row2"
            type="checkbox"
            label={field?.label}
            checked={formData[field?.name]}
            onChange={(e) => {
              setFormData({ ...formData, [field?.name]: e.target.checked })
            }}
            name={field?.name}
          />
        </Form.Group>

      )
    } else if (field?.input_type === 'select') {
      return (
        <Form.Group className="getstart-form-field">
          <Form.Label>
            {field?.label}
          </Form.Label>
          <div className="contact-input ">
            <Select
              onChange={(e) => {
                console.log(e.value);
                setFormData({ ...formData, [field?.name]: e.value })
              }}
              // value={formData[field?.name]}
              options={field?.options?.map(opt => ({ value: opt?.value, label: opt?.label }))}
              styles={customStyles}
              placeholder={<span>{field?.placeholder} </span>}
            />
          </div>
          {/* <h6>Error message goes here</h6> */}
        </Form.Group>
      )
    } else {
      return (
        <Form.Group
          className={`getstart-form-field`}
        >
          <Form.Label>{field?.label}</Form.Label>

          <div className="contact-input  ">
            <Form.Control
              required={field?.required}
              name={field?.name}
              value={formData[field?.name]}
              onChange={(e) => {
                setFormData({ ...formData, [field?.name]: e.target.value })
              }}
              type={field?.input_type}
              placeholder={field?.placeholder}
            />
          </div>
        </Form.Group>
      )
    }
  }


  return (
    <div className="getstarted-content">
      <div className="container">
        <div className="getstarted-wrapper">
          <div className="getstarted-head">
            <h2>{data?.title}</h2>
            <p>{data?.sub_title}</p>
          </div>
          <div className="getstarted-form-wrapper">
            <Form>
              <div className="getstarted-form-section">
                <div className="getstarted-form-row" style={{ display: 'flex', flexWrap: "wrap" }}>
                  {data?.section_1_form_fields?.map((field) => {
                    return renderFormGroupField(field)
                  })}
                </div>
              </div>


              <div className="getstarted-form-section">
                <div className="getstarted-form-row" style={{ display: 'flex', flexDirection: "row", flexWrap: "wrap" }}>
                  {data?.section_2_form_fields?.map((field) => {
                    return renderFormGroupField(field)
                  })}
                </div>
              </div>


              <div className="getstarted-form-section">
                <h3>{data?.section_3_title}</h3>
                <div className="getstarted-form-row" style={{ display: 'flex', flexDirection: "row", flexWrap: "wrap" }}>
                  {data?.section_3_form_fields?.map((field) => {
                    return renderFormGroupField(field)
                  })}
                </div>
              </div>

              <div className="getstarted-form-section">
                <div className="getstarted-form-row" style={{ display: 'flex', flexDirection: "row", flexWrap: "wrap" }}>
                  {data?.section_4_form_fields?.map((field) => {
                    return renderFormGroupField(field)
                  })}
                </div>
              </div>



              <div className="getstarted-form-section">
                <h3>{data?.section_5_title}</h3>
                <div className="getstarted-form-row" style={{ display: 'flex', flexDirection: "row", flexWrap: "wrap" }}>
                  {data?.section_5_form_fields?.map((field) => {
                    return renderFormGroupField(field)
                  })}
                </div>
              </div>

              <div className="getstarted-form-section">
                <div className="getstarted-form-row" style={{ display: 'flex', flexDirection: "row", flexWrap: "wrap" }}>
                  {data?.section_6_form_fields?.map((field) => {
                    return renderFormGroupField(field)
                  })}
                </div>
              </div>
              <div className="getstarted-form-section">
                <Button onClick={submitForm} disabled={!formData || Object.keys(formData).length < 7}>
                  {/* <Link to="/booking">Book a Call with our team!</Link> */}
                  Submit
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GetStarted
