import React from "react"
import GetStarted from "../components/GetStarted/getStarted"
import Layout from "../components/Layout/layout"

const GetStartedPage = ({pageContext}) => {
  const {footer,navBar,getStartedPage} = pageContext;
  return (
    <div className="getstarted-page">
      <Layout footer={footer} navBar={navBar}>
        <GetStarted data={getStartedPage} />
      </Layout>
    </div>
  )
}

export default GetStartedPage
